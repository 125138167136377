import { Component, Inject, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { FormGroup, FormBuilder, Validators, FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ErrorStateMatcher } from '@angular/material/core';
import { Tenant, AuthenticationService, User, Site } from '@empusa/empusa-core';
import { ActivatedRoute, Router } from '@angular/router';
import { TenantService } from '../../../client/tenant.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RolService } from '../../../client/rol.service';
import { SiteService } from '../../../client/site.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserService } from '../../../client/user.service';
import { MicroappusermanagementGenericComponent } from '../../generic/microapp-usermanagement-generic.component';
import { MatTableDataSource } from '@angular/material/table';
import { EditUserComponent } from '../../user/edit/microapp-usermanagement.edit-user.component';
import { MatPaginator } from '@angular/material/paginator';
import { Floor } from '@empusa/empusa-core/auth/floor';
import { AccessRigths } from '@empusa/empusa-core/auth/accessRights';
import { AccessGroup } from '@empusa/empusa-core/auth/accessGroup';
// import { AccessGroup } from '../../../../../../node_modules/@empusa/empusa-core/auth/accessGroup';

@Component({
  selector: 'empusa-microapp-usermanagement.edit-tenant',
  templateUrl: './microapp-usermanagement.edit-tenant.component.html',
  styleUrls: ['./microapp-usermanagement.edit-tenant.component.css']
})
export class EditTenantComponent {

  /** form to validate */
  tenantForm: FormGroup;
  /** the tenant being edited */
  editTenant: Tenant = new Tenant();
  matcher = new MyErrorStateMatcher();
  currentUser: User;
  loading: boolean;
  floorSub: string;
  users = [];
  tenantBillingId = []
  tenantId: string;
  floorList: Floor[] = [];
  accessGroupList: AccessGroup[] = [];
  accessList: AccessRigths[] = [];
  displayedColumns: string[] = ['mail', 'fullname', 'rol', 'actions'];
  dataSource = new MatTableDataSource([]);
  site: string;
  building: Site;
  showAccessGroup: boolean = false;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private activatedRoute: ActivatedRoute, private tenantService: TenantService,
    private userService: UserService, private dialog: MatDialog,
    private snackBar: MatSnackBar, public dialogRef: MatDialogRef<EditTenantComponent>,
    private translate: TranslateService, private router: Router,
    private formBuilder: FormBuilder, private location: Location, private rolService: RolService,
    private siteService: SiteService, private auth: AuthenticationService,
    @Inject(MAT_DIALOG_DATA) public ticket_id: string,
    @Inject('environment') private environment) {

    this.tenantForm = this.formBuilder.group({
      uid: [],
      name: ['', [Validators.required, Validators.pattern('^[^()]+$')]],
      floor: ['', Validators.required],
      // sublocation: ['', Validators.required],
      description: [''],
      access: ['', Validators.required],
      accessGroup: ['', Validators.required],
      billingTenantId: ['', Validators.compose(this.quantityValidator())]
    });
    this.auth.loadCurrentSite().then(site => {
      this.site = site.uid;
      this.building = site;
      this.tenantService.getTenantsYardi(this.site).subscribe(data => {
        this.tenantBillingId = data.leases;
        this.tenantBillingId.forEach(tenant => {
          tenant.billingTenantName = tenant.billingTenantName.replaceAll("(", "-").replaceAll(")", "-")
        })
        this.tenantBillingId.sort((a, b) =>
          a.billingTenantName.toLowerCase() > b.billingTenantName.toLowerCase() ? 1 :
            a.billingTenantName.toLowerCase() < b.billingTenantName.toLowerCase() ? -1 :
              0
        );
      })

    })

    // getTenantsYardi

    auth.getUser().then(user => {
      this.currentUser = user;

      //getting the user info from backend

      // this.activatedRoute.params.subscribe(params => {
      //   let tenant_id: string = params['id'];

      // if (this.building.name == "Razer") {
      //   this.showAccessGroup = true;
      // }

      this.showAccessGroup = this.environment.show_acess_group;

      if (this.showAccessGroup) {
        this.tenantService.getAccessGroup().subscribe(resp => {
          for (let i = 0; i < resp.access_groups.length; i++) {
            this.accessGroupList.push(resp.access_groups[i]);
          }
        })
      }
      this.tenantService.getFloor().subscribe(resp => {
        for (let i = 0; i < resp.home_floors.length; i++) {
          this.floorList.push(resp.home_floors[i]);
        }
      });

      this.tenantService.getAccess().subscribe(resp => {
        for (let i = 0; i < resp.access_rights.length; i++) {
          this.accessList.push(resp.access_rights[i]);
        }
      })



      let tenant_id: string = ticket_id;
      if (tenant_id) {
        //we are editting
        this.loading = true;
        this.tenantService.getTenant(tenant_id).subscribe(resp => {

          this.editTenant = resp;
          this.g.uid.setValue(resp.uid);
          this.g.name.setValue(resp.name);
          this.g.description.setValue(resp.description);
          if (resp.accessRights != null) {
            let access_id: string[] = [];
            for (let i = 0; i < resp.accessRights.length; i++) {
              access_id.push(resp.accessRights[i].AccessRightId);
            }
            this.g.access.setValue(access_id);
          }

          if (resp.floor != null) {
            let floor_id: string[] = [];
            for (let i = 0; i < resp.floor.length; i++) {
              floor_id.push(resp.floor[i].HomeFloorId);
            }
            this.g.floor.setValue(floor_id);
          }

          if (this.showAccessGroup) {
            if (resp.accessGroups != null) {
              let access_id: string[] = [];
              for (let i = 0; i < resp.accessGroups.length; i++) {
                access_id.push(resp.accessGroups[i].AccessGroupId);
              }
              this.g.accessGroup.setValue(access_id);
            }
          } else {
            this.g.accessGroup.setValue('no');
            // } else {

          }


          this.g.billingTenantId.setValue(resp.billingTenantId);
          this.tenantId = tenant_id;
          // this.getUsers();
          this.loading = false;
        });

      } else {
        //we are adding a new tenant
        this.editTenant = new Tenant();
        this.g.uid.setValue("");
        this.g.name.setValue("");
        this.g.description.setValue("");
        this.g.floor.setValue("");
        let accessGrouInit = "";
        if (!this.showAccessGroup) {
          accessGrouInit = "no"
        }
        this.g.accessGroup.setValue(accessGrouInit);
        // this.g.sublocation.setValue("");
        this.g.billingTenantId.setValue("");
      }
      // });
    });

  }

  // convenience getter for easy access to form fields
  get g(): any {
    return this.tenantForm.controls;
  }


  onSubmit() {
    // stop here if form is invalid
    if (this.tenantForm.invalid) {
      return;
    }
    this.loading = true;
    this.editTenant.name = this.g.name.value;
    // this.editTenant.name = this.editTenant.name.trim();
    this.editTenant.description = this.g.description.value;
    this.editTenant.floor = this.g.floor.value;
    this.editTenant.accessRights = this.g.access.value;
    // this.editTenant.sublocation = this.g.sublocation.value;
    this.editTenant.billingTenantId = this.g.billingTenantId.value;
    this.editTenant.is_site = false;
    if (this.showAccessGroup) {
      this.editTenant.accessGroups = this.g.accessGroup.value;
      // }else{
      //   this.g.accessGroup.setValue('');
    }
    if (this.editTenant.uid) {
      //we are editting
      this.tenantService.updateTenant(this.editTenant, this.currentUser.sites[0].dn).subscribe(
        data => {
          this.snackBar.open(this.translate.instant("MAPP-USERMANAGEMENT.EDITTENANT.OK.MESSAGE"),
            this.translate.instant("MAPP-USERMANAGEMENT.EDITTENANT.OK.TITLE"), {
            duration: 5000,
          });
          let user: User = {...this.currentUser};
          let cloneTenant = { ... this.editTenant };
          cloneTenant.floor = [];
          cloneTenant.accessRights = [];
          cloneTenant.accessGroups = []
          let listFloorValues: [] = this.g.floor.value;
          let listARValues: [] = this.g.access.value;
          listFloorValues.forEach((xa: string) => {
            let floorToIncl = this.floorList.find(x => x.HomeFloorId == xa);
            if (floorToIncl) {
              cloneTenant.floor.push(floorToIncl);
            }
          })

          listARValues.forEach((xa: string) => {
            let aRToIncl = this.accessList.find(x => x.AccessRightId == xa);
            if (aRToIncl) {
              cloneTenant.accessRights.push(aRToIncl);
            }
          })

          if (this.showAccessGroup) {
            let listAGValues: [] = this.g.accessGroup.value;
            listAGValues.forEach((xa: string) => {
              let aGToIncl = this.accessGroupList.find(x => x.AccessGroupId == xa);
              if (aGToIncl) {
                cloneTenant.accessGroups.push(aGToIncl);
              }
            })
          }

          user.sites![0].tenants![0] = cloneTenant;
          this.loading = false;
          this.dialogRef.close(this.editTenant);
        },
        error => {
          this.loading = false;
          this.snackBar.open(this.translate.instant("MAPP-USERMANAGEMENT.EDITTENANT.ERROR.MESSAGE"),
            this.translate.instant("MAPP-USERMANAGEMENT.EDITTENANT.ERROR.TITLE"), {
            duration: 5000,
          });
        });
    } else {
      //we are creating a new one
      this.tenantService.addTenant(this.editTenant, this.currentUser.sites[0].dn).subscribe(
        data => {
          this.snackBar.open(this.translate.instant("MAPP-USERMANAGEMENT.EDITTENANT.OK.MESSAGE"),
            this.translate.instant("MAPP-USERMANAGEMENT.EDITTENANT.OK.TITLE"), {
            duration: 5000,
          });
          this.loading = false;
          this.dialogRef.close(this.editTenant);
        },
        error => {
          this.loading = false;
          this.snackBar.open(this.translate.instant("MAPP-USERMANAGEMENT.EDITTENANT.ERROR.MESSAGE"),
            this.translate.instant("MAPP-USERMANAGEMENT.EDITTENANT.ERROR.TITLE"), {
            duration: 5000,
          });
        });
    }
  }

  selectedTenant(tenantBillingID) {
    this.g.billingTenantId.setValue(tenantBillingID)
  }

  /**
 * @name toBack
 * @description cancel the edition
 */
  toBack() {
    this.location.back();
  }

  // createOrEdit(ticket_id: string) {
  //   let dialogAssign = this.dialog.open(EditUserComponent, {
  //     width: '680px',
  //     data: ticket_id
  //   });
  //   dialogAssign.afterClosed().subscribe(result => {
  //     this.getUsers();
  //   });
  // }

  // deleteUser(uid: string) {
  //   let dialogRef = this.dialog.open(MicroappusermanagementGenericComponent, {
  //     data: {
  //       title: 'DIALOG.DELETE.TITLE',
  //       question: 'DIALOG.DELETE.QUESTION',
  //       icon: 'delete'
  //     }
  //   });
  //   dialogRef.afterClosed().subscribe(result => {
  //     if (result) {
  //       this.userService.deleteUser(uid).subscribe(
  //         resp => {
  //           this.getUsers();
  //           this.snackBar.open(this.translate.instant("DIALOG.DELETE.CONFIRMATION"),
  //             this.translate.instant("DIALOG.DELETE.TITLE"), {
  //             duration: 5000,
  //           });
  //         },
  //         error => {
  //           this.snackBar.open(this.translate.instant("DIALOG.DELETE.ERROR"),
  //             this.translate.instant("DIALOG.DELETE.TITLE"), {
  //             duration: 5000,
  //           });
  //         }
  //       );
  //     }
  //   });
  // }

  // sendNot(user_id: string) {
  //   let dialogRef = this.dialog.open(MicroappusermanagementGenericComponent, {
  //     data: {
  //       title: 'Resend Email',
  //       question: 'Are you sure of resend email to the user?',
  //       icon: 'email'
  //     }
  //   });

  //   dialogRef.afterClosed().subscribe(result => {
  //     if (result) {
  //       this.userService.resentEmail(user_id).subscribe(data => {

  //         this.snackBar.open(this.translate.instant("Email resent"),
  //           this.translate.instant("OK"), {
  //           duration: 5000,
  //         });
  //       },
  //         error => {
  //           if (error.status === 409) {
  //             this.snackBar.open(this.translate.instant("User not found"),
  //               this.translate.instant("ERROR"), {
  //               duration: 5000,
  //             });

  //           } else {
  //             this.snackBar.open(this.translate.instant("Error resending the email"),
  //               this.translate.instant("ERROR"), {
  //               duration: 5000,
  //             });
  //           }
  //         }
  //       );
  //     }
  //   });
  // }

  // getUsers() {
  //   this.loading = true;
  //   this.userService.findUsersByTenant(this.tenantId).subscribe(resp => {
  //     this.loading = false;
  //     let tempDataSource = new MatTableDataSource(resp.users);
  //     tempDataSource.paginator = this.paginator
  //     this.dataSource = tempDataSource;
  //   },
  //     error => {
  //       this.loading = false;
  //       this.snackBar.open(
  //         this.translate.instant("No se han cargado los datos correctamente"),
  //         this.translate.instant("MAPP-FITTINGOUT.UPDATE.FAILLOAD.TITLE")
  //         , {
  //           duration: 5000,
  //         });
  //     });
  // }

  quantityValidator(): any[] {
    let validatorArr: any[] = [];
    validatorArr.push(Validators.required);
    validatorArr.push(Validators.min(8));
    validatorArr.push(Validators.pattern("[t][0-9]{7}"));
    validatorArr.push(Validators.maxLength(8));
    return validatorArr;
  }
  // towers = [
  //   { sub: 'North' },
  //   { sub: 'South' },
  // ]
  //   floors = [
  //     { floor: '1;Front;NorthTower' }
  //     // { floor: '2' },
  //     // { floor: '3' },
  //     // { floor: '4' },
  //     // { floor: '5' },
  //     // { floor: '6' },
  //     // { floor: '7' },
  //     // { floor: '8' },
  //     // { floor: '9' },
  //     // { floor: '10' },
  //     // { floor: '11' },
  //   ];
}

// export class homeFlorrs {
//   home_floors: string[];
//   message: string;
//   success: boolean
//   total_home_floors: number;


// }

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
